import React from "react";

import { PageProps } from "gatsby";
import NotFound from "./404";
import NewsLayout from "layouts/NewsLayout";
import ServiceYahooArticle from "components/News/ServiceYahooArticle";
import { useQuery } from "libs/utils";

type Props = PageProps<
  object,
  object,
  {
    articleID?: string;
  }
>;

export default function Page({ location }: Props) {
  const sid = location.state?.articleID || useQuery("id")[0];
  const id = parseInt(sid) || 0;

  if (id < 1) return <NotFound />;

  return (
    <NewsLayout backButton={true}>
      <ServiceYahooArticle id={id} className="whitespace-pre-wrap" />
    </NewsLayout>
  );
}
